@font-face {
    font-family: "Poppins";
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
    src: url(./Poppins-Regular.ttf);
    src: url(./Poppins-Regular.ttf) format("ttf"),
  }
  @font-face {
    font-family: "Poppins";
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
    src: url(./Poppins-Medium.ttf);
    src: url(./Poppins-Medium.ttf) format("ttf"),  
  }
  @font-face {
    font-family: "Poppins";
    font-weight: 600;
    font-style: normal;
    font-display: fallback;
    src: url(./Poppins-SemiBold.ttf);
    src: url(./Poppins-SemiBold.ttf) format("ttf"),  
  }